import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { FC } from "react";
import { embeddedAssetBlockImageUi, embeddedEntryBlockUi } from "../../helpers/richTextEditorUi";

const BlogDetailView: FC<{ content: any }> = ({ content }) => {
  const options = {
    renderText: (text) => text.replace("!", "?"),

    renderNode: {
      "embedded-asset-block": (node) => embeddedAssetBlockImageUi(node, content),
      "embedded-entry-block": (node) => embeddedEntryBlockUi(node, content),
    },
  };

  return (
    <React.Fragment>
      <section className="services-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {documentToReactComponents(JSON.parse(content?.raw), options)}
            </div>
            {/*  */}
          </div>
        </div>
        <div className="vector-shape6">
          <img src={"/images/shape/vector-shape6.png"} alt="about" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default BlogDetailView;
