import SEO from "@/components/SEO";
import { SEOType } from "@/types";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout from "../../components/App/Layout";
import BreadCrumb from "../../components/Shared/extra/BreadCrumb";
import BlogDetailView from "./BlogDetailView";

export const query = graphql`
  query ($slug: String) {
    contentfulBlogModel(slugUrl: { eq: $slug }) {
      title
      breadcrumbTitle
      slugUrl
      seo {
        metaTitle
        metaDescription
        metaUrl
        metaAuthor
        metaKeywords
        metaImage {
          file {
            url
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulBulletPointsRichTextEditorRef {
            contentful_id
            points
            __typename
          }
          ... on ContentfulAsset {
            id
            contentful_id
            file {
              url
            }
          }
        }
      }
    }
  }
`;

interface BlogDetailProps extends PageProps {
  data: {
    contentfulBlogModel: {
      title: string;
      breadcrumbTitle?: string;
      slugUrl: string;
      seo: SEOType;
      content: {
        raw: any;
        references: any[];
      };
    };
  };
}

const BlogDetail: React.FC<BlogDetailProps> = ({ data: { contentfulBlogModel } }) => {
  return (
    <Layout>
      <SEO contentfulSeo={contentfulBlogModel.seo} />
      <BreadCrumb
        pageTitle={contentfulBlogModel.breadcrumbTitle || contentfulBlogModel.title}
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/blog/", pathName: "Blogs" },
          {
            path: "/",
            pathName: contentfulBlogModel.breadcrumbTitle || contentfulBlogModel.title,
          },
        ]}
      />
      {/*  */}

      <BlogDetailView content={contentfulBlogModel.content} />
    </Layout>
  );
};

export default BlogDetail;
